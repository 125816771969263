.antModal {
  :global(.ant-modal-content) {
    border-radius: 18px;
  }

  :global(.ant-modal-title) {
    font-weight: 500;
  }

  :global(.ant-modal-close) {
    top: 11px;
  }
}
