@import "../../variables.module.scss";

.dashboard {
  min-height: 100vh;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 68px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding-left: 88px;

  // Свойство авто также делает скролбары вне контента - не увеличивает ширину/высоту элементов
  overflow: auto;

  .topNav {
    margin-right: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .pageTitle {
      display: flex;
      align-items: center;
      h1 {
        font-size: 1.5rem;
        font-weight: 500;
        display: flex;
        align-items: center;

        .backBtn {
          padding: 0;
          background-color: transparent;
          border: none;
          white-space: nowrap;
          font-size: 0;
          margin-right: 16px;
        }
      }
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      > div {
        margin-left: 20px;
        // cursor: pointer;
        &:first-child {
          margin-left: 0;
        }
      }

      .calendar {
        display: flex;
        align-items: center;

        .datePicker {
          padding: 0;
          width: 92px;

          input {
            font-size: 0.938rem;
            text-align: right;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }

      .support,
      .profile,
      .notification {
        button {
          background: transparent;
          padding: 0;
          border: 0;
          svg {
            vertical-align: middle;
          }
        }
      }

      .profile {
        font-size: 0;
        > button {
          > span {
            display: flex;
          }
        }
      }
    }
  }

  .mainPage {
    margin: 16px 0;
  }
}

.popupDatePicker {
  :global(.ant-picker-panel-container) {
    border-radius: 18px;
  }

  :global(.ant-picker-header) {
    background-color: $colorPrimarlyGreen;

    :global(button) {
      color: $colorSecondaryWhite;
      span {
        color: inherit;
      }
    }

    :global(.ant-picker-header-view) {
      color: $colorSecondaryWhite;
      font-size: 0.938rem;
      font-weight: 500;

      button:hover {
        color: $colorSecondaryBlack;
      }
    }
  }

  :global(.ant-picker-cell) {
    vertical-align: middle;
  }

  :global(.ant-picker-cell):global(.ant-picker-cell-in-view) {
    :global(.ant-picker-cell-inner) {
      &::before {
        border-color: transparent;
      }
    }
  }

  :global(.ant-picker-cell):not(:global(.ant-picker-cell-in-view)) {
    :global(.ant-picker-cell-inner) {
      color: $colorGreyThird;
    }
  }

  :global(.ant-picker-cell):global(.ant-picker-cell-selected) {
    :global(.ant-picker-cell-inner) {
      border-radius: 18px;
      background-color: $colorPrimarlyGreen;

      &::before {
        border-color: transparent;
      }
    }
  }
}

:global(#error-page) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
