@import "../../../../variables.module.scss";

.popup {
  border-radius: 18px;
  box-shadow: 0 4px 8px 4px rgba($color: #000000, $alpha: 0.04);
  background-color: $colorSecondaryWhite;
  padding: 8px 0;
  a {
    background-color: transparent;
    border: 0;
    padding: 0;
    padding: 6px 44px;
    position: relative;
    display: inline-block;
    color: $colorSecondaryBlack;
    font-size: 0.813rem;
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      left: 22px;
    }
  }
}
