@font-face {
  font-family: "Montserrat";
  src:
    local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src:
    local("Montserrat"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}
